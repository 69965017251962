@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  color: #262626;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.font-alt {
  font-family: "Montserrat", sans-serif;
}

.my-limitless {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navigation {
  padding: 0 10px;
  transition: max-height 0.2s ease;
  width: calc(100% - 27px);
  z-index: 10;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

.tds-list,
.tds-text--body,
.tds-text--caption-header,
.tds-text--h1,
.tds-text--h2,
.tds-text--h3,
.tds-text--h4,
.tds-text--h5,
.tds-text--h6 {
  line-height: 20px;
}

.tds-list > :is(li, dd):not(:last-of-type) {
  -webkit-margin-after: 8px;
  margin-block-end: 8px;
}

.navigation-link {
  margin: 0;
  padding: 0;
}

hr {
  margin: 0.5rem 0px;
}

.tds-list > :is(li, dd) {
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  position: relative;
}

[dir="ltr"] .navigation-link a {
  padding-left: 46px;
}

.navigation-link.disabled a {
  color: #aaa;
}

.navigation-link a {
  box-shadow: none;
  color: var(--tds-color--grey30);
  display: block;
  font-family: var(--tds-font-combined);
  font-size: 12px;
  font-weight: 500;
  line-height: 34px;
  transition: color 0.2s ease;
}

a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}

.navigation-link.selected .scrim,
.navigation-title .scrim {
  background: var(--tds-color--grey65);
  border-radius: 50%;
}

[dir="ltr"] .navigation-link .scrim,
[dir="ltr"] .navigation-title .scrim {
  margin-left: -46px;
  margin-right: 12px;
}

.navigation-link .scrim,
.navigation-title .scrim {
  display: inline-flex;
  align-items: center;
  height: 34px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  vertical-align: bottom;
  width: 34px;
}

.navigation-link.selected svg,
.navigation-link:hover svg {
  opacity: 1;
}

.navigation-link svg {
  margin-bottom: 1px;
  opacity: 0.72;
  transition: opacity 0.2s ease;
  height: 20px;
}

.wrapper {
  flex: 1;
  display: flex;
  overflow: auto;
  background: #f5f5f5;
}

.wrapper main {
  flex: 1;
  margin-top: 25px;
}

.buttons {
  display: flex;
  align-items: center;
}

.button-row {
  display: flex;
}

.header .logo {
  padding: 15px;
}

.header .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.footer {
  padding: 15px;
  text-align: center;
  background-color: #262626;
  color: #bdbdbd;
  font-size: 8px;
  font-family: "Montserrat", sans-serif;
}

.website {
  margin-right: 25px;
}

.website a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  padding: 6px;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
}

.website a img {
  margin-right: 8px;
}

.notification {
  margin-right: 25px;
}

.notification a {
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile {
}

.profile a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 18px;
  padding: 6px;
  padding-right: 40px;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  background-repeat: no-repeat;
  background-position: right center;
  background-position-x: 115px;
}

.profile a img {
  margin-right: 8px;
  width: 28px;
}

.login-form {
  width: 350px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 20px;
}

.login-title-sm {
  font-size: 10px;
}

.login-title-md {
  font-size: 16px;
  margin-bottom: 25px;
}

.login-line {
  text-align: center;
}

.login-line input {
  width: 100%;
  border: 1px solid #262626;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.button-dark {
  font-family: "Montserrat", sans-serif;
  background: #262626;
  border-radius: 8px;
  font-size: 10px;
  color: #fff !important;
  padding: 12px 50px;
  box-shadow: none;
  -webkit-appearance: button;
  outline: none;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
}

.button-dark.narrow {
  padding: 10px;
}

.button-white {
  font-size: 10px;
  color: #262626;
  text-decoration: none;
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-right: 15px;
  transition-duration: 0.3s;
}

.button-white:hover {
  background: #262626;
  color: #fcd801;
}

.login-form a {
  font-family: "Montserrat", sans-serif;
  color: #262626;
  text-decoration: none !important;
  font-size: 10px;
}

.bookmarks {
  padding: 15px 0;
  background: #f5f5f5;
}

.bookmarks .link {
  text-decoration: none !important;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #262626;
  height: 100%;
  transition-duration: 0.3s;
}

.bookmarks .link:hover {
  background: #262626;
  color: #fcd801;
}

.dashboard .row,
.dashboard .col,
.dashboard .ba-card {
  height: 100%;
}

.dashboard .ba-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.box {
  margin: 12px 0;
  flex: 1;
  background-size: cover;
  border-radius: 15px;
  overflow: hidden;
  text-decoration: none;
  font-size: 16px;

  transition-duration: 0.3s;
}

.box.hover {
  color: #fff;
}

.box.hover:hover {
  color: #fcd801;
}

.box .title {
  font-family: "Montserrat", sans-serif;
  margin-left: 15px;
  margin-top: 15px;
}

.box .welcome {
  display: inline;
  font-size: 20px;
  border-bottom: 1px solid #fcd800;
  color: #262626;
  font-weight: bold;
}

.box .yacht-logo {
  margin-top: 25px;
}

.box .conf-id {
  font-size: 12px;
  color: #262626;
  text-decoration: none;
}

.box .buttons {
  margin-top: 20px;
}

.sheet {
  border-radius: 15px;
  padding: 20px;
  background: #fff;
  margin-bottom: 25px;
}

.nav {
  list-style: none;
  padding: 0;
}

.nav > li {
  margin-bottom: 20px;
}

.nav > li > .parent {
  font-size: 12px;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav > li.active .parent {
  color: #262626;
}

.nav > li > .parent.disabled {
  color: #7f7f7f;
}

.nav > li > .icon {
  margin-right: 10px;
}

.nav .text {
  flex: 1;
  padding-left: 10px;
}

.nav-child ul {
  list-style: none;
  margin-top: 10px;
}

.nav-child ul > li > a {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #262626;
  text-decoration: none;
}

.sidenav {
  height: 100%;
  overflow: auto;
}

.sidenav li {
  width: 100%;
}

.title-sub {
  font-size: 10px;
}

.form-wrapper,
.list-wrapper {
  margin-top: 15px;
}

.form .row {
  margin-top: 15px;
}

.form-item label {
  display: block;
  font-size: 10px;
}

.form-item input[type="text"],
.form-item input[type="date"],
.form-item textarea {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #262626;
  padding: 10px 15px;
  outline: none;
  margin-top: 5px;
}

.form-item select {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #262626;
  padding: 10px 15px;
  outline: none;
  margin-top: 5px;
}

.form-item {
  margin-bottom: 15px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-head {
  font-size: 10px;
}

.table-body {
  color: #878787;
  font-size: 10px;
}

.table-row {
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #878787;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.status-accepted {
  color: #00ef38;
}

.status-rejected {
  color: #ff0000;
}

.sub-active {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  margin-right: 7px;
}

.spec {
  margin-bottom: 25px;
  border-bottom: 1px solid #7f7f7f;
}

.spec-title {
  font-size: 10px;
  color: #7f7f7f;
  min-height: 15px;
}

.spec-text {
  font-size: 14px;
}

.warranty-items {
}

.warranty-item {
  display: flex;
  padding: 10px 15px;
  border: 1px solid #878787;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: none;
}

.warranty-item:hover {
  background-color: #ebebeb;
}

.warranty-item .text {
  font-size: 11px;
  color: #373636;
}

.warranty-item .icon {
}

.row-title {
  cursor: pointer;
}

.table-row .row-content {
  height: 0;
  color: #2c3e50;
  background: white;
  transition: all 1s;
  display: none;
}

.table-row.active .row-content {
  height: auto;
  display: block;
}

.row-content-inner {
  padding: 10px 0;
}

.table-row .row {
  position: relative;
}

.table-row .row::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  position: absolute;
  right: 0;
}

.table-row.no-content .row::after {
  content: "";
}

.table-row.active .row::after {
  transform: rotate(90deg);
}

.header .hamburger {
  display: none;
}

.show-mobile {
  display: none !important;
}

.nav-icon {
  /* display: none; */
  display: flex;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  left: -100vw;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 14px 10px 0 #666;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  z-index: 99999;
}

.mobile-nav.active {
  left: 0;
}

.mobile-nav-inner {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.mobile-navigation {
  flex: 1;
  padding: 10px;
  overflow: auto;
}

.mobile-website {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-nav-footer {
  border-top: 1px solid #ccc;
  padding: 10px;
}

.logout a {
  color: #262626;
  text-decoration: none !important;
}

.mobile-logout {
  text-align: right;
}

.mobile-logout a {
  color: #000 !important;
  text-decoration: none !important;
}

.serialnumber {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  padding: 10px 15px;
  border: 1px solid #262626;
  border-radius: 8px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .buttons {
    flex-direction: column;
  }

  .mobile-nav {
    width: 80vw;
  }

  .button-row {
    flex: 1;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
  }

  .button-row .button-white {
    flex: 1;
    padding: 10px 6px;
    text-align: center;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-icon {
    display: flex;
    align-items: center;
  }

  .show-mobile {
    display: initial !important;
  }

  .hide-mobile {
    display: none;
  }

  .header .buttons {
    display: none;
  }

  .header .hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .bookmarks {
    padding: 5px 0;
  }

  .bookmarks .link {
    font-size: 8px;
  }

  .box .title {
    margin: 0;
    padding: 20px 80px 135px 20px;
  }

  .button-dark {
    padding: 10px;
  }

  .service-row .col-12 {
    margin-bottom: 5px;
    color: #000;
  }

  .service-title {
    font-size: 14px;
  }
}

/* Nav Icon */

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}
.ham2 .top {
  stroke-dasharray: 40 121;
}
.ham2 .bottom {
  stroke-dasharray: 40 121;
}
.ham2.active .top {
  stroke-dashoffset: -102px;
}
.ham2.active .bottom {
  stroke-dashoffset: -102px;
}
.ham3 .top {
  stroke-dasharray: 40 130;
}
.ham3 .middle {
  stroke-dasharray: 40 140;
}
.ham3 .bottom {
  stroke-dasharray: 40 205;
}
.ham3.active .top {
  stroke-dasharray: 75 130;
  stroke-dashoffset: -63px;
}
.ham3.active .middle {
  stroke-dashoffset: -102px;
}
.ham3.active .bottom {
  stroke-dasharray: 110 205;
  stroke-dashoffset: -86px;
}
.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}
.ham5 .top {
  stroke-dasharray: 40 82;
}
.ham5 .bottom {
  stroke-dasharray: 40 82;
}
.ham5.active .top {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}
.ham5.active .bottom {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}
.ham6 .top {
  stroke-dasharray: 40 172;
}
.ham6 .middle {
  stroke-dasharray: 40 111;
}
.ham6 .bottom {
  stroke-dasharray: 40 172;
}
.ham6.active .top {
  stroke-dashoffset: -132px;
}
.ham6.active .middle {
  stroke-dashoffset: -71px;
}
.ham6.active .bottom {
  stroke-dashoffset: -132px;
}
.ham7 .top {
  stroke-dasharray: 40 82;
}
.ham7 .middle {
  stroke-dasharray: 40 111;
}
.ham7 .bottom {
  stroke-dasharray: 40 161;
}
.ham7.active .top {
  stroke-dasharray: 17 82;
  stroke-dashoffset: -62px;
}
.ham7.active .middle {
  stroke-dashoffset: 23px;
}
.ham7.active .bottom {
  stroke-dashoffset: -83px;
}
.ham8 .top {
  stroke-dasharray: 40 160;
}
.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}
.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}
.ham8.active .top {
  stroke-dashoffset: -64px;
}
.ham8.active .middle {
  stroke-dashoffset: -20px;
  transform: rotate(90deg);
}
.ham8.active .bottom {
  stroke-dashoffset: -64px;
}
